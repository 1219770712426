import Vue from 'vue';
import VueRouter from 'vue-router';
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法


Vue.use(VueRouter);
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

let routes = [
    //首页
    {
        path: '/',
        component: () => import('@/views/ld/index.vue'),
        children: [{
            path: '',
            name: 'index',
            meta: {
                title: '利德世普科技有限公司'
            },
            component: () => import('@/views/ld/index.vue')
        }
        ]
    },
    //产品与服务
    {
        path: '/productServices',
        name: 'ProductServices',
        component: () => import('@/views/ld/productServices/index.vue'),

    },
    //新闻中心
    {
        path: '/news-center',
        name: 'NewsCenter',
        component: () => import('@/views/ld/news/index.vue'),

    },
    //新闻中心--详情
    {
        path: '/news-detail',
        name: 'NewsDetail',
        component: () => import('@/views/ld/news/newsDetail.vue'),

    },
    //合作伙伴
    {
        path: '/partners',
        name: 'Partners',
        component: () => import('@/views/ld/partners/index.vue'),

    },
    //关于我们
    {
        path: '/about-us',
        name: 'AboutUs',
        component: () => import('@/views/ld/aboutUs/index.vue'),

    },

    //联系我们
    {
        path: '/contact-us',
        name: 'ContactUs',
        component: () => import('@/views/ld/contactUs/index.vue'),

    },




];

let router = new VueRouter({
    mode: 'history',
    routes: routes,
    scrollBehavior() { // 解决vue页面之间跳转，页面不是在顶部的问题
        return { x: 0, y: 0 }
    }
});


router.beforeEach((to, from, next) => {
    const title = to.meta && to.meta.title;
    if (title) {
        document.title = title;
    }
    next();
});

export default router;
