import Vue from 'vue';

import {
    ButtonPlugin,
    DropdownPlugin,
    FormPlugin,
    FormInputPlugin,
    FormTextareaPlugin,
    SidebarPlugin,
    OverlayPlugin,
    LinkPlugin,
    ImagePlugin,
    LayoutPlugin,
    NavbarPlugin,
    ModalPlugin,
    ListGroupPlugin,
    SkeletonPlugin,
    TabsPlugin,
    CardPlugin,
    PaginationPlugin
} from "bootstrap-vue";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

Vue.use(ButtonPlugin)
    .use(DropdownPlugin)
    .use(FormPlugin)
    .use(FormInputPlugin)
    .use(FormTextareaPlugin)
    .use(SidebarPlugin)
    .use(OverlayPlugin)
    .use(LinkPlugin)
    .use(ImagePlugin)
    .use(LayoutPlugin)
    .use(ModalPlugin)
    .use(NavbarPlugin)
    .use(ListGroupPlugin)
    .use(SkeletonPlugin)
    .use(TabsPlugin)
    .use(CardPlugin)
    .use(PaginationPlugin)
